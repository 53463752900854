import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getSuffix } from "../../utils/utils.ts";
import Loading from "../common/Loading/Loading.tsx";
import { forwardByToken } from "../../api/forward-api.ts";
import { useAppDispatch } from "../../store/store.ts";

type ForwarderProps = {
  location: Location;
};

function Forwarder({ location }: ForwarderProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    const token = getSuffix("/", location.pathname);
    if (token !== undefined) {
      dispatch(forwardByToken(token, () => history.push("/")));
    }
  }, [dispatch, history, location.pathname]);
  return <Loading />;
}

export default Forwarder;

import type { ListPayload, ShoppingList } from "../model/shopping-list";
import type { Break, ShiftTime } from "../model/shift";
import type { UserInfo } from "../utils/auth";
import type { CurrentChannel, SearchResult } from "../api/channels-api";
import type { Forward } from "../model/forward";
import type { Subscriber } from "rxjs";

export type AppState = {
  userInfo?: UserInfo;
  isMobile: boolean;
  progress: number;
  shoppingList: ShoppingList;
  shift: { desired: ShiftTime; startOfWork: ShiftTime; breaks: Break[] };
  resumeToken: string | undefined;
  keycloakToken: string | undefined;
  channels: SearchResult | undefined;
  channelScroll: number;
  channelSearch: string;
  currentChannel?: CurrentChannel;
  forwards: Forward[];
  listPayloadSubscriber: Subscriber<ListPayload> | undefined;
  rSocketIds: string[];
};

export const defaultState: AppState = {
  userInfo: undefined,
  isMobile: false,
  progress: 0,
  shoppingList: { todo: [], done: [] },
  shift: {
    desired: { hours: 7, minutes: 48 },
    startOfWork: { hours: 7, minutes: 0 },
    breaks: [],
  },
  resumeToken: undefined,
  keycloakToken: undefined,
  channels: undefined,
  channelScroll: 0,
  channelSearch: "",
  currentChannel: undefined,
  forwards: [],
  listPayloadSubscriber: undefined,
  rSocketIds: [],
};

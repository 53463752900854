import { toast } from "react-toastify";
import Toastbody from "../component/common/ToastBody/Toastbody";
import { setProgress } from "../store/actions";
import type { AxiosResponse } from "axios";
import type { AppDispatch } from "../store/store";

type ProgressEvent = "start" | "success" | "error";

export const httpProgress = (
  event: ProgressEvent,
  dispatch: AppDispatch,
): void => {
  switch (event) {
    case "start":
      dispatch(setProgress(50));
      break;
    case "success":
      dispatch(setProgress(100));
      break;
    case "error":
      dispatch(setProgress(0));
      break;
  }
};

export const httpError = (
  reason: unknown,
  title = "Fehler aufgetreten",
): void => {
  toast.error(Toastbody({ title, body: `${reason}` }));
};

export const handleResponse = (
  response: AxiosResponse<unknown, unknown>,
  dispatch: AppDispatch,
  error_title: string,
  successCallback: () => void,
) => {
  if (response.status >= 200 && response.status < 300) {
    httpProgress("success", dispatch);
    successCallback();
  } else {
    httpProgress("error", dispatch);
    httpError(`HTTP Error ${response.status}`, error_title);
  }
};

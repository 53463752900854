import { Redirect, Route, type RouteProps } from "react-router-dom";
import type { Role } from "../../../utils/auth";
import { getUserInfo } from "../../../store/selectors";
import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import type { Observable, Subscriber } from "rxjs";
import type { ListPayload } from "../../../model/shopping-list";
import { useAppSelector } from "../../../store/store";

type PrivateRouteProps = RouteProps & {
  component: React.FunctionComponent<unknown>;
  path: string | string[];
  role: Role;
  subscriber?: Subscriber<ListPayload>;
  observable?: Observable<ListPayload>;
};

export default function PrivateRoute(
  { component, role, ...rest }: PrivateRouteProps,
) {
  const { keycloak } = useKeycloak();
  const userInfo = useAppSelector(getUserInfo);
  const hasAccess = keycloak?.authenticated && userInfo !== undefined &&
    userInfo.roles.indexOf(role) !== -1;

  return (
    <Route
      {...rest}
      render={(props) => (hasAccess
        ? React.createElement(component, { ...props, ...rest })
        : <Redirect to="/" />)}
    />
  );
}

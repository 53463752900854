import axios, { type AxiosResponse } from "axios";
import Env from "../env";

type VersionInfo = {
  version: string;
};

export const getVersion = (): Promise<string> =>
  axios.get(`${Env.NEW_BACKEND_URL}/frontend/version`).then((
    res: AxiosResponse<VersionInfo, unknown>,
  ) => res.data.version);

export const setVersion = (version: string): Promise<string> =>
  axios
    .post(
      `${Env.NEW_BACKEND_URL}/frontend/version/set`,
      { version } as VersionInfo,
    )
    .then((res: AxiosResponse<VersionInfo, unknown>) => res.data.version);

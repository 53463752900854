import { configureStore, type ThunkAction } from "@reduxjs/toolkit";
import rootReducer from "./reducer";
import type { AppState } from "./state";
import type { TypedAction } from "./actions";
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from "react-redux";

const AppStore = configureStore({
  reducer: rootReducer,
});
export default AppStore;

export type RootState = ReturnType<typeof AppStore.getState>;
export type AppDispatch = typeof AppStore.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunkAction<R> = ThunkAction<
  R,
  AppState,
  undefined,
  TypedAction
>;
export type AsyncAppAction<R> = AppThunkAction<Promise<R>>;

import Keycloak from "keycloak-js";
import Env from "./env";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak: Keycloak = new Keycloak({
  url: Env.KEYCLOAK_URL,
  realm: "l94",
  clientId: "l94-frontend",
});

export default keycloak;

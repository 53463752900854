import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function Home() {
  const { keycloak } = useKeycloak();
  const history = useHistory();

  useEffect(() => {
    if (keycloak.authenticated === true) {
      history.replace("/authed");
    }
  }, [history, keycloak.authenticated]);

  return <></>;
}

// deno-lint-ignore-file no-explicit-any
import React from "react";
import type { Role } from "./utils/auth";

const Routes = [
  "/forwarder",
  "/keys",
  "/cart",
  "/shift",
  "/konto",
  "/channels",
  "/channels/:id",
  "/authed",
  "/version",
] as const;
export type Route = (typeof Routes)[number];
export function isRoute(route: string): route is Route {
  return Routes.map((r) => `${r}`).indexOf(route) !== -1;
}

type PreloadFactory = () => Promise<{ default: React.ComponentType<unknown> }>;
type PreloadableLazyComponent =
  & React.LazyExoticComponent<React.ComponentType<unknown>>
  & { preload: PreloadFactory };
function lazyWithPreload(
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  factory: () => Promise<{ default: React.ComponentType<any> }>,
): PreloadableLazyComponent {
  const Component = React.lazy(factory);
  return { ...Component, preload: factory } as PreloadableLazyComponent;
}

const UrlTableComponent = lazyWithPreload(() =>
  import("./component/UrlTable/UrlTable")
);
const OfficeKeysComponent = lazyWithPreload(() =>
  import("./component/OfficeKeys/OfficeKeys")
);
const ShoppingListComponent = lazyWithPreload(() =>
  import("./component/ShoppingList/ShoppingList")
);
const ShiftComponent = lazyWithPreload(() => import("./component/Shift/Shift"));
const AccountComponent = lazyWithPreload(() =>
  import("./component/Account/Account")
);
const ListChannelsComponent = lazyWithPreload(() =>
  import("./component/Channels/ListChannels")
);
const ChannelDetailsComponent = lazyWithPreload(() =>
  import("./component/Channels/ChannelDetails")
);
const AuthedHomeComponent = lazyWithPreload(() =>
  import("./component/AuthedHome/AuthedHome")
);
const VersionComponent = lazyWithPreload(() =>
  import("./component/Version/Version")
);

export type RouteConf = {
  title: string;
  component: PreloadableLazyComponent;
  role?: Role;
};

export const RouteConfig: Record<Route, RouteConf> = {
  "/forwarder": {
    title: "Links",
    component: UrlTableComponent,
    role: "l94:linker",
  },
  "/keys": {
    title: "Schlüssel",
    component: OfficeKeysComponent,
    role: "l94:keys",
  },
  "/cart": {
    title: "Einkaufen",
    component: ShoppingListComponent,
    role: "l94:shopping-list",
  },
  "/shift": {
    title: "Schicht",
    component: ShiftComponent,
    role: "l94:shifts",
  },
  "/konto": {
    title: "Konto",
    component: AccountComponent,
  },
  "/channels": {
    title: "Kanäle",
    component: ListChannelsComponent,
    role: "l94:channels",
  },
  "/channels/:id": {
    title: "Kanal",
    component: ChannelDetailsComponent,
    role: "l94:channels",
  },
  "/authed": {
    title: "Home",
    component: AuthedHomeComponent,
    role: "l94:shopping-list",
  },
  "/version": {
    title: "Version",
    component: VersionComponent,
    role: "l94:shopping-list",
  },
};

import type { KeyboardEventHandler } from "react";

export function getSuffix(prefix: string, target: string): string | undefined {
  return !!target && target.length > prefix.length && target.startsWith(prefix)
    ? target.substring(prefix.length)
    : undefined;
}

export const onEnterKey = (callback: () => void): KeyboardEventHandler => {
  return (event) => {
    if (event.key === "Enter") {
      callback();
    }
  };
};

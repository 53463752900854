import axios, { type AxiosResponse } from "axios";
import type { AsyncAppAction } from "../store/store";
import { handleResponse, httpError, httpProgress } from "./common";
import type { Forward, Token, User } from "../model/forward";
import { removeForward, setForward, setForwards } from "../store/actions";
import Env from "../env";

export const forwardByToken = (
  token: string,
  noForwardCallback: () => void,
): AsyncAppAction<void> => {
  return async (dispatch) => {
    try {
      httpProgress("start", dispatch);
      await axios
        .post(`${Env.BACKEND_URL}/forward/token`, { token })
        .then((response: AxiosResponse<Forward, unknown>) => {
          httpProgress("success", dispatch);
          if (response.data.forward && !!response.data.url) {
            console.log(
              `https://l94.de – Weiterleitung nach: ${response.data.url}`,
            );
            document.title = "Du wirst weitergeleitet…";
            globalThis.location.href = response.data.url;
          } else {
            noForwardCallback();
          }
        })
        .catch((reason: unknown) => {
          httpProgress("error", dispatch);
          httpError(reason, "Abfrage fehlgeschlagen");
        })
        .finally();
    } catch (error) {
      httpProgress("error", dispatch);
      console.error(error);
      httpError(error);
    }
  };
};

export const getList = (): AsyncAppAction<void> => {
  return async (dispatch) => {
    try {
      httpProgress("start", dispatch);
      await axios
        .get(`${Env.BACKEND_URL}/forward/forward-list`)
        .then((response: AxiosResponse<User, unknown>) => {
          handleResponse(
            response,
            dispatch,
            "Laden der Liste fehlgeschlagen",
            () => {
              dispatch(setForwards(response.data.forwards));
            },
          );
        })
        .catch((reason: unknown) => {
          httpProgress("error", dispatch);
          httpError(reason, "Laden der Liste fehlgeschlagen");
        })
        .finally();
    } catch (error) {
      httpProgress("error", dispatch);
      console.error(error);
      httpError(error);
    }
  };
};

export const createForward = (url: string): AsyncAppAction<void> => {
  return async (dispatch) => {
    try {
      httpProgress("start", dispatch);
      await axios
        .post(`${Env.BACKEND_URL}/forward/new`, { url })
        .then((response: AxiosResponse<Token, unknown>) => {
          handleResponse(
            response,
            dispatch,
            "Erstellen der Weiterleitung fehlgeschlagen",
            () => {
              dispatch(
                setForward({ forward: true, token: response.data.token, url }),
              );
            },
          );
        })
        .catch((reason: unknown) => {
          httpProgress("error", dispatch);
          httpError(reason, "Erstellen der Weiterleitung fehlgeschlagen");
        })
        .finally();
    } catch (error) {
      httpProgress("error", dispatch);
      console.error(error);
      httpError(error);
    }
  };
};

export const changeForward = (
  token: string,
  url: string,
): AsyncAppAction<void> => {
  return async (dispatch, getState) => {
    try {
      httpProgress("start", dispatch);
      await axios
        .post(`${Env.BACKEND_URL}/forward/change`, { token, url })
        .then((response: AxiosResponse<Token, unknown>) => {
          handleResponse(
            response,
            dispatch,
            "Bearbeiten der Weiterleitung fehlgeschlagen",
            () => {
              dispatch(
                setForward({
                  forward: getState().forwards.find((f) =>
                    f.token === token
                  )?.forward ?? false,
                  token,
                  url,
                }),
              );
            },
          );
        })
        .catch((reason: unknown) => {
          httpProgress("error", dispatch);
          httpError(reason, "Bearbeiten der Weiterleitung fehlgeschlagen");
        })
        .finally();
    } catch (error) {
      httpProgress("error", dispatch);
      console.error(error);
      httpError(error);
    }
  };
};

export const deleteForward = (token: string): AsyncAppAction<void> => {
  return async (dispatch) => {
    try {
      httpProgress("start", dispatch);
      await axios
        .post(`${Env.BACKEND_URL}/forward/delete`, { token })
        .then((response: AxiosResponse<Token, unknown>) => {
          handleResponse(
            response,
            dispatch,
            "Entfernen der Weiterleitung fehlgeschlagen",
            () => {
              dispatch(removeForward(token));
            },
          );
        })
        .catch((reason: unknown) => {
          httpProgress("error", dispatch);
          httpError(reason, "Entfernen der Weiterleitung fehlgeschlagen");
        })
        .finally();
    } catch (error) {
      httpProgress("error", dispatch);
      console.error(error);
      httpError(error);
    }
  };
};

import type { ReactElement } from "react";
import styles from "./Toastbody.module.scss";

type ToastBodyProps = {
  title: string;
  body: string;
};
export default function Toastbody(
  { title, body }: ToastBodyProps,
): ReactElement {
  const renderedBody =
    body === "TypeError: NetworkError when attempting to fetch resource."
      ? "Keine Verbindung zum Backend!"
      : body;
  return (
    <div className={styles.Component}>
      <span className={styles.Title}>{title}</span>
      <span className={styles.Body}>{renderedBody}</span>
    </div>
  );
}

import type { ShoppingListItem } from "../model/shopping-list";
import type { Break, ShiftTime } from "../model/shift";
import type { UserInfo } from "../utils/auth";
import type { CurrentChannel, SearchResult } from "../api/channels-api";
import type { Forward } from "../model/forward";
import type { AnyAction } from "redux";

export const Actions = [
  "set-progress",
  "set-user-info",
  "add-shopping-list-item",
  "update-shopping-list-item",
  "remove-shopping-list-items",
  "set-shopping-list-items",
  "add-rsocket-id",
  "remove-rsocket-id",
  "set-keycloak-token",
  "shift-set-start",
  "shift-set-desired",
  "shift-add-break",
  "shift-set-break",
  "set-channels",
  "set-channels-scroll",
  "set-channel-search",
  "set-current-channel",
  "set-forwards",
  "set-forward",
  "remove-forward",
] as const;
export type Action = AnyAction & {
  type: (typeof Actions)[number];
};

export type AddShoppingListItemAction = Action & {
  type: "add-shopping-list-item";
  item: ShoppingListItem;
};

export type UpdateShoppingListItemAction = Action & {
  type: "update-shopping-list-item";
  item: ShoppingListItem;
};

export type RemoveShoppingListItemsAction = Action & {
  type: "remove-shopping-list-items";
  ids: string[];
};

export type SetShoppingListItemsAction = Action & {
  type: "set-shopping-list-items";
  items: ShoppingListItem[];
};

export type AddRSocketId = Action & {
  type: "add-rsocket-id";
  id: string;
};

export type RemoveRSocketId = Action & {
  type: "remove-rsocket-id";
  id: string;
};

export type SetProgressAction = Action & {
  type: "set-progress";
  progress: number;
};

export type SetUserInfoAction = Action & {
  type: "set-user-info";
  userInfo: UserInfo;
};

export type SetShiftDesiredAction = Action & {
  type: "shift-set-desired";
  desired: ShiftTime;
};

export type SetShiftStartAction = Action & {
  type: "shift-set-start";
  start: ShiftTime;
};

export type ShiftAddBreakAction = Action & {
  type: "shift-add-break";
  break: Break;
};

export type ShiftSetBreakAction = Action & {
  type: "shift-set-break";
  breakType: keyof Break;
  index: number;
  time: ShiftTime;
};

export type SetKeycloakTokenAction = Action & {
  type: "set-keycloak-token";
  token: string | undefined;
};

export type SetChannelsAction = Action & {
  type: "set-channels";
  channels: SearchResult;
};

export type SetChannelScrollAction = Action & {
  type: "set-channels-scroll";
  scrollTop: number;
};

export type SetChannelSearchAction = Action & {
  type: "set-channel-search";
  searchTerm: string;
};

export type SetCurrentChannelAction = Action & {
  type: "set-current-channel";
  channel: CurrentChannel | undefined;
};

export type SetForwardsAction = Action & {
  type: "set-forwards";
  forwards: Forward[];
};

export type SetForwardAction = Action & {
  type: "set-forward";
  forward: Forward;
};

export type RemoveForwardAction = Action & {
  type: "remove-forward";
  token: string;
};

export type TypedAction =
  & Action
  & (
    | AddShoppingListItemAction
    | UpdateShoppingListItemAction
    | RemoveShoppingListItemsAction
    | SetShoppingListItemsAction
    | AddRSocketId
    | RemoveRSocketId
    | SetKeycloakTokenAction
    | SetProgressAction
    | SetUserInfoAction
    | SetShiftDesiredAction
    | SetShiftStartAction
    | ShiftAddBreakAction
    | ShiftSetBreakAction
    | SetChannelsAction
    | SetChannelScrollAction
    | SetChannelSearchAction
    | SetCurrentChannelAction
    | SetForwardsAction
    | SetForwardAction
    | RemoveForwardAction
  );

export const addShoppingListItem = (
  item: ShoppingListItem,
): AddShoppingListItemAction => {
  return { type: "add-shopping-list-item", item };
};

export const updateShoppingListItem = (
  item: ShoppingListItem,
): UpdateShoppingListItemAction => {
  return { type: "update-shopping-list-item", item };
};

export const removeShoppingListItems = (
  ids: string[],
): RemoveShoppingListItemsAction => {
  return { type: "remove-shopping-list-items", ids };
};

export const setShoppingListItems = (
  items: ShoppingListItem[],
): SetShoppingListItemsAction => {
  return { type: "set-shopping-list-items", items };
};

export const addRSocketId = (
  id: string,
): AddRSocketId => {
  return { type: "add-rsocket-id", id };
};

export const removeRSocketId = (id: string): RemoveRSocketId => {
  return { type: "remove-rsocket-id", id };
};

export const SetKeycloakToken = (
  token: string | undefined,
): SetKeycloakTokenAction => {
  return { type: "set-keycloak-token", token };
};

export const setProgress = (progress: number): SetProgressAction => {
  return { type: "set-progress", progress };
};

export const setUserInfo = (userInfo: UserInfo): SetUserInfoAction => {
  return { type: "set-user-info", userInfo };
};

export const setShiftDesired = (desired: ShiftTime): SetShiftDesiredAction => {
  return { type: "shift-set-desired", desired };
};

export const setShiftStart = (start: ShiftTime): SetShiftStartAction => {
  return { type: "shift-set-start", start };
};

export const shiftAddBreak = (): ShiftAddBreakAction => ({
  type: "shift-add-break",
  break: { start: { hours: 0, minutes: 0 }, end: { hours: 0, minutes: 0 } },
});

export const shiftSetBreak = (
  time: ShiftTime,
  index: number,
  breakType: keyof Break,
): ShiftSetBreakAction => ({
  type: "shift-set-break",
  breakType,
  index,
  time,
});

export const setChannels = (channels: SearchResult): SetChannelsAction => {
  return { type: "set-channels", channels };
};

export const setChannelScroll = (scrollTop: number): SetChannelScrollAction => {
  return { type: "set-channels-scroll", scrollTop };
};

export const setChannelSearch = (
  searchTerm: string,
): SetChannelSearchAction => {
  return { type: "set-channel-search", searchTerm };
};

export const setCurrentChannel = (
  channel: CurrentChannel | undefined,
): SetCurrentChannelAction => {
  return { type: "set-current-channel", channel };
};

export const setForwards = (forwards: Forward[]): SetForwardsAction => {
  return { type: "set-forwards", forwards };
};

export const setForward = (forward: Forward): SetForwardAction => {
  return { type: "set-forward", forward };
};

export const removeForward = (token: string): RemoveForwardAction => {
  return { type: "remove-forward", token };
};

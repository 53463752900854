import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import AppStore from "./store/store";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import type {
  AuthClientError,
  AuthClientEvent,
  AuthClientTokens,
} from "@react-keycloak/core";
import keycloak from "./keycloak";
import { SetKeycloakToken } from "./store/actions";
import axios from "axios";
import { httpError } from "./api/common";

export type KeyCloakEventHandler = (
  eventType: AuthClientEvent,
  error?: AuthClientError,
) => void;
const keycloakEventHandler = (
  eventType: AuthClientEvent,
  error?: AuthClientError,
) => {
  switch (eventType) {
    case "onTokenExpired":
    case "onAuthRefreshError":
      keycloak.updateToken(300);
      break;
    case "onReady":
    case "onAuthSuccess":
    case "onAuthLogout":
    case "onAuthRefreshSuccess":
      break;
    default: {
      if (error !== undefined) {
        httpError(`${error.error}: ${error.error_description}`, eventType);
      } else {
        httpError(eventType);
      }
    }
  }
};

const onKeycloakTokens = (tokens: AuthClientTokens) => {
  AppStore.dispatch(SetKeycloakToken(tokens.token));
  if (tokens.token !== undefined) {
    axios.defaults.headers.common.Authorization = `Bearer ${tokens.token}`;
  }
};

const container = document.getElementById("root");
// biome-ignore lint/style/noNonNullAssertion: TODO
const root = createRoot(container!);

root.render(
  <ReactKeycloakProvider
    autoRefreshToken={true}
    authClient={keycloak}
    onEvent={keycloakEventHandler}
    onTokens={onKeycloakTokens}
  >
    <React.StrictMode>
      {/*https://github.com/react-keycloak/react-keycloak/issues/182#issuecomment-1128477421*/}
      <Provider store={AppStore}>
        <App />
      </Provider>
    </React.StrictMode>
  </ReactKeycloakProvider>,
);

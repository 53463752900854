// deno-lint-ignore-file ban-unused-ignore

type Environment = {
  BACKEND_URL: string;
  NEW_BACKEND_URL: string;
  KEYCLOAK_URL: string;
  WS_URL: string;
  DEBUG_WS: boolean;
};

// deno-lint-ignore no-unused-vars
const dev: Environment = {
  BACKEND_URL: "http://localhost:36455",
  NEW_BACKEND_URL: "http://localhost:8081",
  KEYCLOAK_URL: "http://localhost:8080/",
  WS_URL: "ws://127.0.0.1:6792",
  DEBUG_WS: true,
};

// deno-lint-ignore no-unused-vars
const prod: Environment = {
  BACKEND_URL: "https://api.l94.de",
  NEW_BACKEND_URL: "https://api-rs.l94.de",
  KEYCLOAK_URL: "https://auth.l94.de/",
  WS_URL: "wss://api.l94.de:443/rsocket/",
  DEBUG_WS: false,
};

const Env = prod;
export default Env;

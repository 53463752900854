const Roles = [
  "l94:channels",
  "l94:keys",
  "l94:linker",
  "l94:shifts",
  "l94:shopping-list",
  "l94:admin",
] as const;
export type Role = (typeof Roles)[number];

export type UserInfo = {
  username: string;
  userId: string;
  roles: Role[];
  email_verified: boolean;
  locale: string;
};

export function parseUser(userInfo: unknown): UserInfo {
  const user = userInfo as {
    email_verified: boolean;
    locale: string;
    preferred_username: string;
    realm_access: { roles: string[] };
    sub: string;
  };
  return {
    username: user.preferred_username,
    userId: user.sub,
    roles: user.realm_access.roles.filter((r) =>
      (Roles as readonly string[]).indexOf(r) !== -1
    ) as Role[],
    email_verified: user.email_verified,
    locale: user.locale,
  };
}
